import { Typography } from "antd"
import gota_agua from '../../../../../assets/images/gota2.png'

const Dias = ({dia,gota,mg})=>{
    return<>
        <div
            key={dia}
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                border:'1px solid #64B02A',
                width:100,
                
                marginTop:10,
                borderRadius:8,
                marginLeft:30
            }}
        >
            <div style={{}}>
                <Typography.Paragraph>Dia {dia}</Typography.Paragraph>
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center',backgroundColor:'#A7C1A4',width:'100%',color:'#fff'}}>
                <div 
                    style={{
                        backgroundImage: `url(${gota_agua})`,
                        backgroundSize: 'cover', // Puedes ajustar el tamaño de la imagen según tus necesidades
                        backgroundPosition: 'center', // Puedes ajustar la posición de la imagen
                        width: 20,
                        height: 20,
                        
                    }}
                ></div>
                <div style={{marginTop:10}}>
                    <Typography.Paragraph style={{color:'#fff'}}>{gota}</Typography.Paragraph>
                </div>
            </div>
            <div style={{marginTop:10}}>
                <Typography.Paragraph>{mg} mg</Typography.Paragraph>
            </div>
        </div>
    </>
}

export default Dias