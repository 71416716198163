import { Form, Select, Typography } from "antd"
import useSWR from 'swr'
import API from "../../../../API"

const Producto = () => {
    const fetcher = (url) => {
        return API(url).then(d => d?.data)
    }

    const producto = useSWR('api/productos/producto/', fetcher)
    const required = { required: true, message: 'Complete este campo' }
    return <>
        <Typography.Title level={3} style={{ textAlign: 'center' }}>Selecciona el producto</Typography.Title>
        <Form.Item name='producto' rules={[required]} style={{marginTop:'10%'}}>
            <Select
                placeholder='Escoja el producto'
                options={producto?.data?.map(e => ({ value: e?.id, label: e?.producto }))}
            />
        </Form.Item>
    </>
}

export default Producto