import axios from "axios";

//export const BASE_URL= 'http://192.168.1.33:9000/' 
export const BASE_URL= 'https://admin.greenoil.site/'


console.log(BASE_URL)

const API=axios.create({
    baseURL:BASE_URL,
    headers:{
        Accept: 'application/json',
        "Content-Type": "application/json",
        
    }

})
    const arrApi = [
            'api/configuracion/patologia/',
            'api/configuracion/rango-peso/',
            'api/configuracion/rango-edad/',
            'api/productos/producto/',
            'api/productos/indicador/',
            'api/preguntas/pregunta/'
        ]


    API.interceptors.request.use(
        async (config)=> {
            const tokenUsuario = await localStorage.getItem('token')
            
            if (tokenUsuario && !arrApi.includes(config.url)) {
                config.headers['Authorization'] = `Bearer ${tokenUsuario}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    
    )




export default API

