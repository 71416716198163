import { App, Button, Form, Select, Typography } from "antd"
import useSWR from 'swr'
import API from "../../../../API"

const Formulario = ({}) => {
    const [form] = Form.useForm()

    const fetcher = (url) => {
        return API(url).then(d => d?.data)
    }

    const patologia = useSWR('api/configuracion/patologia/',fetcher)
    const edad = useSWR('api/configuracion/rango-edad/',fetcher)
    const peso = useSWR('api/configuracion/rango-peso/',fetcher)
    const required = { required: true, message: 'Complete este campo' }
    return <>
        <Typography.Title level={3} style={{ textAlign: 'center' }}>Ingrese sus datos</Typography.Title>
        <Form.Item name='edad' rules={[required]}>
            <Select
                placeholder='Edad en Años'
                options={edad?.data?.map(e=>({value:e?.id,label:e?.rango_edad}))}

            />
        </Form.Item>
        <Form.Item name='peso' rules={[required]}>
            <Select
                placeholder='Peso en Kg'
                options={peso?.data?.map(e=>({value:e?.id,label:e?.rango_peso}))}
            />
        </Form.Item>
        <Form.Item name='patologia' rules={[required]}>
            <Select
                placeholder='Patología'
                options={patologia?.data?.map(e=>({value:e?.id,label:e?.patologia}))}
                
            />
        </Form.Item>
        {/* <Form.Item  style={{display:'flex',justifyContent:'flex-end'}}>
            <Button htmlType="submit" style={{ backgroundColor: '#64B02A', color: '#fff', borderColor: '#64B02A' }}>Calcular</Button>
        </Form.Item> */}
    </>
}

export default Formulario