import { Route, Routes } from "react-router-dom"
import Home from "./Pages/Home"
import ProtectedPage from "./Components/ProtectedPage"
import Preguntas from "./Pages/Preguntas"
import { ConfigProvider } from "antd"


const App = () => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#64B02A',
                    // borderRadius: 6,
                    // // Alias Token
                    // colorBgContainer: '#f6ffed',
                },
            }}
        >
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/preguntas" element={<Preguntas />} />
            </Routes>
        </ConfigProvider>
    )
}

export default App