import { Button, Card, Collapse, Typography } from "antd"
import PiePagina from "../Home/components/PiePagina"
import { useNavigate } from "react-router-dom"
import API from "../../API"
import { useEffect, useState } from "react"


const Preguntas = () => {
    const [preguntas,setPreguntas] = useState([])
    const listpreguntas = async()=>{
        try {
            const {data} = await API.get('api/preguntas/pregunta/')
            setPreguntas(data)
        } catch (error) {
            console.log(error)
        }
    }
    const navigate = useNavigate()

    useEffect(()=>{
        listpreguntas()
    },[])
    return <div style={{ padding: 20, marginTop: '10%' }}>
        <div className="fondo"></div>
        <Card>
            <div>
                <Button type="primary" onClick={()=>navigate('/')}>Ir a la Calculadora</Button>
            </div>
            <div style={{display:'flex',justifyContent:'center'}}>
                <Typography.Title>Preguntas de Frecuentes</Typography.Title>
            </div>
            {
                preguntas?.map(e=>(
                    <>
                        <Collapse
                            style={{marginBottom:20}}
                            items={[
                                {
                                    key:e?.id,
                                    label:e?.pregunta,
                                    children:e?.respuestas?.map(j=>(
                                        <>
                                            <Typography.Paragraph>{j?.respuesta}</Typography.Paragraph>
                                        </>
                                    ))
                                }
                            ]}
                        />
                    </>
                ))
            }
        </Card>
        <PiePagina />
    </div>
}

export default Preguntas