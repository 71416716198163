import { Button, Card, Col, Form, Row, Typography } from "antd"
import Formulario from "./components/Formulario"
import Producto from "./components/Producto"
import { useEffect, useState } from "react"
import API from "../../API"
import Dosis from "./components/Dosis"
import logo from "../../assets/images/goilpr22.png"
import info2 from '../../assets/images/info2.png'
import { Footer } from "antd/es/layout/layout"
import PiePagina from "./components/PiePagina"
import { useNavigate } from "react-router-dom"

const Home = () => {
    const navigate = useNavigate()
    const [cont, setCont] = useState(0)
    const [idPro, setIdPro] = useState(0)
    const [dato, setDato] = useState({})
    const [dias, setDias] = useState([])
    const [form] = Form.useForm()
    const datos = (data) => {
        console.log(data)
    }

    const consulta = async (payload) => {
        try {
            if (cont === 0) {
                setIdPro(payload.producto)
                setCont(cont + 1)
            } else if (cont === 1) {
                const { data } = await API.get('api/productos/indicador/', { params: { ...payload, id_pro: idPro } })
                console.log(data[0])
                setDato(data[0])
                setCont(cont + 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const calculoDias = () => {
        let arr = []
        let cont1 = 1, cont2 = 3
        let gota = dato?.gotas_inicial
        for (let i = 1; i <= 8; i++) {

            let dic = {
                dia: `${cont1}-${cont2}`,
                gota: gota,
                mg: parseFloat(gota * parseFloat(dato?.factor)).toFixed(1)
            }
            // cont2 +=3
            //cont1 +=3
            gota += dato?.aumento_gotas

            if (cont2 > 6) {
                cont2 += 4
            } else {
                cont2 += 3
            }

            if (cont1 > 9) {
                cont1 += 4
            } else {
                cont1 += 3
            }
            arr.push(dic)
        }
        setDias(arr)
    }

    useEffect(() => {
        if (dato) {
            calculoDias()
        }
    }, [dato])


    return <div style={{ padding: 20, margin: '6%' }}>
        <div className="fondo"></div>
        <div style={{
            backgroundImage: `url(${logo})`,
            backgroundSize: 'cover', // Puedes ajustar el tamaño de la imagen según tus necesidades
            width: 380,
            height: 110,

        }}></div>
        <div>
            <div className="logo"></div>
            <Row gutter={32}>
                <Col sm={24} md={10}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <Typography.Title style={{ color: '#fff' }}>Descubre la dosis perfecta para ti</Typography.Title>
                        </div>
                        <div>
                            <Typography.Paragraph style={{ color: '#fff' }}>Nuestra calculadora te brinda la posibilidad de ajustar la cantidad de nuestro producto de cannabis según tu peso, tolerancia y tipo de patología, asegurando un enfoque adaptado a tus necesidades específicas.</Typography.Paragraph>
                        </div>
                        <div>
                            <Row gutter={16}>
                                <Col sm={24} md={12}>
                                    <Button style={{ backgroundColor: '#64B02A', color: '#fff', borderColor: '#64B02A' }}>Ir a la tienda</Button>
                                </Col>
                                <Col sm={24} md={12}>
                                    <Button onClick={()=>navigate('/preguntas')} type="dashed" style={{ color: '#64B02A', borderColor: '#64B02A', backgroundColor: 'transparent',cursor:'pointer' }}>Preguntas Frecuentes</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col sm={24} md={14} >
                    <Card
                        style={{ borderColor: '#64B02A', height: 600, }}
                        id="card"

                    >
                        <Form form={form} layout="vertical" onFinish={consulta}>
                            {cont === 0 && <Producto />}
                            {cont === 1 && <Formulario />}
                            {cont === 2 && <Dosis data={dias} />}
                            <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', bottom: -100, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        {cont > 0 && <Button onClick={() => setCont(cont - 1)}>Volver</Button>}
                                    </div>
                                    <div>
                                        {cont === 0 && <>
                                            <Form.Item noStyle>
                                                <Button type="primary" htmlType="submit">Continuar</Button>
                                            </Form.Item>
                                        </>}
                                        {cont === 1 && <>
                                            <Form.Item noStyle>
                                                <Button type="primary" htmlType="submit">Calcular</Button>
                                            </Form.Item>
                                        </>}
                                        {cont === 2 && <>
                                            <Button type="primary" onClick={() => setCont(0)}>Volver a Calcular</Button>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
        <PiePagina/>
    </div>

}

export default Home