import { Typography } from "antd"
import { Footer } from "antd/es/layout/layout"


const PiePagina = () => {
    return <Footer
        style={{ backgroundColor: 'transparent', marginTop: 20, display: 'flex', justifyContent: 'center' }}

    >
        <div id="footer">
            <div
                className="img"

            ></div>
            <Typography.Paragraph style={{ color: '#A7C1A4' }}>Este dosificador no refleja una formula medica, su finalidad es exclusivamente divulgativa. Esta aplicación terapéutica esta basada en el libro “CBD - A Patient’s Guide To Medical Cannabis” de los Drs. Leonard Leinow y Juliana Birnbaum; donde recomiendan el escalonamiento diario de la dosis hasta que se alcancen los resultados deseados, dejando que el cuerpo regule la cantidad de CBD que se ajuste a su necesidad. Por recomendación de Green Oíl y para su tranquilidad seguridad, usted debe consultar un medico.</Typography.Paragraph>
        </div>
    </Footer>
}

export default PiePagina