import { Button, Card, Col, Row, Space, Typography, notification } from "antd"
import flecha from '../../../../assets/images/flecha_abajo.jpg'
import info from '../../../../assets/images/info.jpg'
import Icon from "@ant-design/icons/lib/components/Icon"
import { InfoCircleOutlined, InfoOutlined } from "@ant-design/icons"
import Dias from "./Dias"


const Dosis = ({ data }) => {
    const alertInfo = () => {
        notification.open({
            type: 'info',
            message: 'Si el resultado no es el deseado, ir aumentando en periodos de 3 días'
        })
    }

    console.log(data,'------data dosi')

    return <>
        <Typography.Title level={3} style={{ textAlign: 'center' }}>Dosis recomendada</Typography.Title>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography.Paragraph>Inicia aquí</Typography.Paragraph>
                <div style={{
                    backgroundImage: `url(${flecha})`,
                    backgroundSize: 'cover', // Puedes ajustar el tamaño de la imagen según tus necesidades
                    backgroundPosition: 'center', // Puedes ajustar la posición de la imagen
                    width: 30,
                    height: 30,
                }}
                ></div>
            </div>
            <div style={{ marginTop: 30 }}>
                {/* <Button onClick={alertInfo} shape="circle" type="primary" icon={<InfoCircleOutlined />} /> */}
                <div style={{
                    backgroundImage: `url(${info})`,
                    backgroundSize: 'cover', // Puedes ajustar el tamaño de la imagen según tus necesidades
                    backgroundPosition: 'center', // Puedes ajustar la posición de la imagen
                    width: 30,
                    height: 30,
                    cursor: 'pointer'
                }}
                    onClick={alertInfo}
                ></div>
            </div>
        </div>
        <Row>
            {
                data?.map(e=><>
                    <Col sm={12} md={6}>
                        <Dias dia={e?.dia} gota={e?.gota} mg={e?.mg}/>
                    </Col>
                </>)
            }
        </Row>

    </>
}

export default Dosis